import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

// Стилізуємо елементи списку
const AddItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: '#c4ffc6',
    marginBottom:    theme.spacing(1),
}));

const WriteOffItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: '#dbdbdb',
    marginBottom:    theme.spacing(1),
}));

export const Balance_history = (props) => {
    const { data, fieldKey } = props;


    const items = data && data[ fieldKey ] ? data[ fieldKey ].map((entry, index) => {
        const ListItemComponent = entry.type === 'add' ? AddItem : WriteOffItem;


        return (
            <ListItemComponent key = { index }>
                <ListItemText
                    primary = { `${entry.type === 'add' ? 'додано:' : 'списано:'}  ${entry.amount}; залишок:  ${entry.remainder}` }
                    secondary = {
                        <>
                            {moment(entry.date).format('DD.MM.YYYY HH:mm:ss')}
                            <br />
                            {entry.comment && <span><span style = {{ fontWeight: '600' }}>Опис:</span> {entry.comment}</span>}
                            <br />
                            {entry.user?.name && <span><span style = {{ fontWeight: '600' }}>Користувач:</span> {entry.user.name}</span>}
                        </>
                    }
                />
            </ListItemComponent>
        );
    }) : null;
    const h2Text = fieldKey === 'balance_history' ? 'Баланс послуг' : 'Баланс телефонії';

    return (

        <List>
            <>
                <h2 style = {{ textAlign: 'center' }}>
                    {h2Text}
                </h2>
                {items}
            </>
        </List>
    );
};
