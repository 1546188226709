/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import   Phone   from '../Phone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Box } from '@mui/material';
import { api } from '../../../Api';
import { MultiSearch } from '../FormConstructor/multiSearch';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';
import { number } from 'prop-types';


const selectState = (state) => ({
    userData: state.swapi.userData,
});

export const Phones = (props) => {
    const state = useSelector(selectState);

    const  userData  = state.userData;

    const { fieldKey, item, setItem, isSingle } = props;
    const [ open, setOpen ] = useState(false);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ isChange, setIsChange ] = useState(false);
    const [ checkedPostman, setCheckedPostman ] = useState([]);
    const [ postmans, setPostmans ] = useState([]);
    const [ openListPostman, setOpenListPostman ] = useState(false);
    useEffect(() => {
        if (open === false) {
            setSelectedItems(item.phones);
        }
    }, [ item.phones, open ]);

    useEffect(() => {
        if (postmans.length >  0) {
            setOpenListPostman(true);
        }
    }, [ postmans ]);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSave = () => {
        confirmAlert({
            title:            'Підтвердження змін',
            message:          '',
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   'Yes',
                    onClick: async () => {
                        console.log('Delete item', item._id);
                        const result = await api.postData({
                            field: fieldKey,
                            selectedItems,
                            id:    item._id,
                        }, `/changePhones/${item._id}`);
                        if (result?.data && setItem) {
                            setItem(result.data);
                        }
                        if (result?.postmans) {
                            //setPostmans(result.postmans);
                        }
                    },
                    className: 'confirm',
                },
                {
                    label:     'No',
                    className: 'cancel',
                },
            ],
        });
        setOpen(false);
    };
    const handleClose = () => {
        setIsChange(false);
        if (isChange) {
            handleSave();
        } else {
            setOpen(false);
        }
    };
    const onChange = (name, value) => {
        setSelectedItems(value);
        const newItem = { ...item };
        newItem[ fieldKey ] = value;
        setIsChange(true);
    };


    const handleCheckboxChange = (postmanId) => {
        setCheckedPostman((prevSelected) => prevSelected.includes(postmanId)
            ? prevSelected.filter((id) => id !== postmanId) // Видалити зі списку
            : [ ...prevSelected, postmanId ], // Додати до списку
        // eslint-disable-next-line function-paren-newline
        );
    };

    // Обробник збереження
    const handleSavePostman = () => {
        confirmAlert({
            title:            'Підтвердження змін',
            message:          '',
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   'Yes',
                    onClick: async () => {
                        console.log('Delete item', item._id);
                        const result = await api.postData({
                            checkedPostman,
                            //numbers:numbers
                        }, `/postman_disable_number/`);
                    },
                    className: 'confirm',
                },
                {
                    label:     'No',
                    className: 'cancel',
                },
            ],
        });
        setOpenListPostman(false);
    };

    console.log(postmans, postmans);

    return (
        <div style = {{ padding: isSingle ? '20px' : 0 }}>
            { userData.role !== 'admin' ? (
                <h3>Підключенні номери</h3>
            ) : null}
            <Box sx = {{ marginBottom: '20px' }}>
                {item.phones && item.phones.length > 0
                    && item.phones.map((phoneObj, index) => (
                        <Chip
                            key = { index }
                            label = { phoneObj.label }
                            sx = {{ marginRight: '5px', marginBottom: '5px' }}
                        />
                    ))
                }
            </Box>
            { userData.role === 'admin' ? (
                <Button
                    color = 'primary'
                    variant = 'contained'
                    onClick = { handleClickOpen }>
                    Управління номерами
                </Button>
            ) : null}


            <Dialog
                maxWidth = { 'lg' }
                open = { open }
                width = { 1200 }
                onClose = { handleClose }>
                <DialogTitle>Додати номер телефону</DialogTitle>
                <DialogContent sx = {{ width: '1200px', height: '600px'  }} >
                    <MultiSearch
                        nameField = 'phones'
                        placeholder = 'Виберіть телефон'
                        setFieldValue = { onChange }
                        type = 'phonesFree'  // передайте дані для пошуку
                        valueField = { selectedItems }
                    />
                    {/* {isList ? (
                        <Phone
                            isCompanyModule
                            limit = { 5 }
                        />
                    ) : (
                        <Button
                            color = 'primary'
                            variant = 'contained'
                            onClick = { ()=>setIslist(true) }>
                            створити
                        </Button>
                    )  } */}

                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'secondary'
                        onClick = { () => {
                            setOpen(false);
                            setIsChange(false);
                        } }>
                        Відмінити
                    </Button>

                    <Button
                        color = 'primary'
                        onClick = { () => handleSave() }>
                        Зберегти
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth = 'lg'
                open = { openListPostman }>
                <DialogTitle>
                    Вкажіть постачальників, номери яких видаляться після наступної оплати
                </DialogTitle>
                <DialogContent sx = {{ width: '1200px', height: '600px' }}>
                    {postmans.length > 0 ? postmans.map((el, i) => (
                        <div
                            key = { i }
                            style = {{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            {/* Кастомний чекбокс */}
                            <input
                                checked = { checkedPostman.includes(el._id) }
                                style = {{ marginRight: '8px' }}
                                type = 'checkbox'
                                onChange = { () => handleCheckboxChange(el._id) }
                            />
                            {/* Ім'я постачальника та номери телефонів */}
                            <span>
                                {el.name}
                            </span>
                        </div>
                    )) : <p>Немає постачальників</p>}
                </DialogContent>
                <DialogActions>
                    <Button
                        color = 'primary'
                        onClick = { handleSavePostman }>
                        Зберегти
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
