import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import * as Yup from 'yup';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { api } from '../../../Api';
import { saveSession } from '../../../instruments';

const selectState = (state) => ({
    isLogged: state.swapi.isLogged,
    userData: state.swapi.userData,
});

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
    password: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
});

function Login() {
    const [ isSend, setIsSend ] = useState(false);
    const [ error, setError ] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const userData = state.userData;
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged) {
            let url = '/';
            if (userData.role !== 'client') {
                url = '/admin/users';
            }
            navigate(url);
        }
        // eslint-disable-next-line
    }, [ isLogged ]);

    const setLogin = async (values) => {
        setIsSend(true);
        const data = await api.postData(values, '/login');
        if (data && data.token) {
            saveSession(data);
            console.log(data, 'setlogin');
            dispatch(apiActions.setIsLogged(true));
            dispatch(apiActions.setUserData(data));
            if (window.location.pathname === '/login') {
                let url = '/';
                if (data.role !== 'client') {
                    url = '/admin/users';
                }
                navigate(url);
            }
        } else if (data && data.error) {
            setError(data.error);
            setIsSend(false);
        }
    };


    return (
        <Container
            maxWidth = 'sm'
            sx = {{ marginTop: '50px' }}>
            <Formik
                initialValues = {{
                    username: '',
                    password: '',
                }}
                validationSchema = { SignupSchema }
                onSubmit = { setLogin }>
                {(formikProps) => (
                    <form onSubmit = { formikProps.handleSubmit }>
                        <Box mb = { 2 }>
                            <Field
                                fullWidth
                                required
                                as = { TextField }
                                autoComplete = 'username'
                                id = 'username'
                                label = 'Username/Email'
                                name = 'username'
                                variant = 'outlined'
                            />
                        </Box>

                        <Box mb = { 2 }>
                            <Field
                                fullWidth
                                required
                                as = { TextField }
                                autoComplete = 'current-password'
                                id = 'password'
                                label = 'Password'
                                name = 'password'
                                type = 'password'
                                variant = 'outlined'
                            />
                        </Box>

                        {error && (
                            <Box mb = { 2 }>
                                <Typography
                                    color = 'error'
                                    variant = 'body2'>
                                    { error }
                                </Typography>
                            </Box>
                        )}

                        <Box mt = { 2 }>
                            <Button
                                fullWidth
                                color = 'primary'
                                disabled = { isSend }
                                type = 'submit'
                                variant = 'contained'>
                                Login
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Container>
    );
}

export default Login;
