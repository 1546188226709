const listing = {};

listing.list = {
    name: {
        type:       'link',
        title:      'Назва',
        width:      '15',
        filter:     'Input',
        typeSearch: 'postman_id',
        link:       '/admin/postman/',
    },

    status: {
        type:      'checkbox',
        title:     'Статус',
        width:     '5',
        component: 'InputCheckbox',
    },
    is_pay_in_active: {
        type:      'checkbox',
        title:     'Списання абон. при підключенні',
        width:     '5',
        component: 'InputCheckbox',
    },

    survisorId: {
        type:      'text',
        title:     'Представник',
        width:     '10',
        component: 'SearchV2',
        filter:    'SearchV2',
    },
    survisorPercent: {
        type:      'text',
        title:     'Процент представника',
        width:     '10',
        component: 'Input',
    },
    createdAt: {
        sort:  true,
        type:  'date',
        title: 'Дата створення',
        width: '5',
    },

    delete: {
        type:   'delete',
        values: [ 'delete' ],
        width:  '5',
    },
};
listing.list_analit = {
    postmanId: {
        type:   'text',
        title:  'Постачальник',
        width:  '15',
        filter: 'SearchV2',
    },


    comment: {
        type:  'text',
        title: 'Коментар',
        width: '20',
    },
    phoneId: {
        type:  'text',
        title: 'Телефон',
        width: '9',
    },

    amount: {
        type:  'text',
        title: 'Сумма',
        width: '5',
    },
    createdAt: {
        sort:   true,
        type:   'date',
        title:  'Дата створення',
        width:  '5',
        filter: 'DateField',
    },

};


export { listing };
