import Cookies from 'js-cookie';

const sessionKey = 'sessionData';

export const getCookieObj = (key) => {
    const sessionData = Cookies.get(key);
    console.log(sessionData, 'getCookieObj');

    const storage = sessionData ? JSON.parse(sessionData) : {};

    return storage;
};


export const getUserData = () => {
    let result = null;

    const storage = getCookieObj(sessionKey);
    if (storage && storage.token) {
        result = storage;
    }

    return result;
};

export const saveSession = (data = {}) => {
    if (data.company_id && data.company_id.length > 0) {
        for (const c of data.company_id) {
            delete c.service;
        }
    }
    const a =  Cookies.set(sessionKey, JSON.stringify(data), { expires: 2 });
    console.log(a, 'saveSession');

    return null;
};

export const removeSession = () => {
    Cookies.remove(sessionKey);
};

export const isLogged = () => {
    let result = false;

    const storage = getCookieObj(sessionKey);
    if (storage && storage.token) {
        result = true;
    }

    return result;
};

export const MorNames = {
    1: 'rsb.pbxvoip.cc',
    2: 'didvoice.com',
    3: 'cc.pbxvoip.cc',
    4: 'dgvoice.me',
    5: 'sip-connecta.tel',
};

export const AtsNames = {
    1: 'АТС 1',
    2: 'АТС 2',
};
